import React from 'react';

import Home from './Views/Home/Home';
import Profile from './Views/Profile/Profile';
import About from './Views/About/About';
import Cources from './Views/Courses/courses';
import Trial from './Views/Trail/trail';

//import Registration from './Views/Registration/Register';


const routes = [
    { path: '/Home', name: '', component: Trial, exact: true },
    { path: '/Profile', name: '', component: Profile, },
    { path: '/about', name: '', component: About, },
    { path: '/courses/:id', name: '', component: Cources, },



    //{ path: '/register', name: '', component: Registration},
]

export default routes;