import React, { Component } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BackToTop from "react-back-to-top-button";
import UserService from '../../services/UserService';
import axios from 'axios';
import Cookies from 'universal-cookie';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Register extends Component {


    constructor(props) {
        super(props);

        this.state = {
            INQGuId: '',
            KGMGuId: '',
            INQParentName: '',
            INQParentEmail: '',
            INQParentMobile: '',
            INQPassword: '',
            INQKidsName: '',
            INQIsLaptop: false,
            INQAlternetNumber: '',
            INQAlternetEmail: '',
            INQCreateDate: '',
            INQStatus: '',
            INQIsDemo: '',
            isSubmitting: false,
            E: '',
            data: [],
            nameerr: '',
            emailerrer: '',
            mobileerroe: '',
            chnames: '',
            ACTCountryCode: '',
            loading1: false,
            loading2: true,
        };








    }

















    render() {

        const { loading1, loading2 } = this.state;
        return (
            <div className="account_page">
                <div className="form-bg h-100">
                    <Container className="h-100">
                        <Row className="align-items-md-center h-100">
                            <Col lg={7} md={7}>
                                <div className="pr-md-5 d-md-block d-none">
                                    <Link to="/"><img src="images/kids4.png" className="img-fluid" /></Link>
                                </div>
                            </Col>

                            <Col lg={5} md={5} className="px-md-0">
                                <form className="form-sample" onSubmit={this.handleSubmit} >
                                    <div className="form-container">
                                        <div className="d-block form-icon">

                                            <div className="d-md-flex d-flex align-items-center justify-content-md-between justify-content-center flex-md-row flex-column  mb-4">
                                                <h4>Prelaunch Offer</h4>
                                                <Link to="/"><img
                                                    src="images/logo.jpg"
                                                    className="img-fluid logo mt-md-n3"
                                                /></Link>
                                            </div>

                                            <div className="text-center py-3">
                                                <h5 className="pb-2 text-secondary">
                                                    Demo Class Schedule will start on
                                                </h5>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <img
                                                        src="favi.png"
                                                        className="img-fluid logo"
                                                        style={{ width: 60 }}
                                                    />
                                                    <h1 className="mb-0 animated bounceIn" style={{ animationIterationCount: 'infinite', animationDuration: '2.5s' }}>

                                                        1<sup>st</sup> October</h1>
                                                </div>


                                            </div>

                                            <div>
                                                <Link to="/" className="btn btn-block btn-theme mt-3 d-block text-white py-3 font-weight-bold">GET Prelaunch Offer</Link>
                                            </div>


                                        </div>
                                    </div>
                                </form>
                            </Col>



                        </Row>
                    </Container>
                </div>

                <BackToTop
                    showOnScrollUp
                    showAt={100}
                    speed={2000}
                    easing="easeInOutQuint"
                    style={{ zIndex: 999, fontSize: 20 }}
                >
                    {/* <button className="btn btn-theme text-white shadow">
            <span><i class="fas fa-chevron-up"></i></span>
          </button> */}
                    <img src="/images/rocket.png" width="60" />
                </BackToTop>

            </div>
        );
    }
}

export default Register;
