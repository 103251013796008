import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import UserService from '../../services/UserService';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      INQGuId: '',
      KGMGuId: '',
      INQParentName: '',
      INQParentEmail: '',
      INQParentMobile: '',
      INQPassword: '',
      INQKidsName: '',
      INQIsLaptop: false,
      INQAlternetNumber: '',
      INQAlternetEmail: '',
      INQCreateDate: '',
      INQStatus: '',
      INQIsDemo: '',
      isSubmitting: false,
      E: '',
      data: [],
      nameerr: '',
      emailerrer: '',
      mobileerroe: '',
      chnames: '',
      loading1: true,
      loading2: true,
      Plan: [],
    };



    fetch(UserService.API_URL1 + 'UserKidsGroup/List', {
      method: 'GET',
      headers: {
        //Authorization: 'Bearer ' + localStorage.getItem('tokens'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'

      }
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ data: response, loading1: false });


        this.state.data.map((item, i) =>
          i == 0 ? (
            fetch(UserService.API_URL1 + 'UserKidsGroupPlan/GetPlan/' + item.kgmGuId, {
              method: 'GET',
              headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'

              }
            })
              .then((response) => response.json())
              .then((response) => {
                this.setState({ KGMGuId: item.kgmGuId })
                console.log("GetPlan", response);
                this.setState({ Plan: response, loading: false, loading2: false });

              },
                err => {
                  console.log(err);

                })
          ) : null


        )



      })

    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    // this.onChangeValue1 = this.onChangeValue1.bind(this);
    // this.CheckEmail = this.CheckEmail.bind(this);
    // this.CheckMobile = this.CheckMobile.bind(this);
    // this.CheckName = this.CheckName.bind(this);
    // this.CheckChildName = this.CheckChildName.bind(this);

  }



  onChangeValue(event) {
    console.log(event);
    this.setState({ KGMGuId: event, loading2: true })

    fetch(UserService.API_URL1 + 'UserKidsGroupPlan/GetPlan/' + event, {
      method: 'GET',
      headers: {
        //Authorization: 'Bearer ' + localStorage.getItem('tokens'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'

      }
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("GetPlan", response);
        this.setState({ Plan: response, loading: false, loading2: false });
      },
        err => {
          console.log(err);
          // this.props.history.push('/Login');
        });
  }


  render() {
    return (
      <div>
        <section className="banner-table">
          <div className="banner-table-center">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col lg={6}>
                  <div className="d-md-none d-block pt-3 text-right">
                    <Link to="/register" className="btn btn-theme2 text-white btn-sm mr-1 rounded-0">Book a Free Trial</Link>
                    <Link to="/" className="btn btn-theme text-white btn-sm rounded-0">Prelaunch Offer</Link>

                  </div>
                  <div className="mt-md-5 mt-2 mt-lg-0 wow zoomIn mr-5" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
                    <img src="images/kids3.png" alt="dental_main" className="img-fluid" />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="banner-text text-left">
                    <h1 className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}> Kickstart Your <span className="animated bounceIn" style={{ color: '#f08634' }}>Kids CODING</span> Journey <br />for Kids from Grade 1-12</h1>
                    <p className="small text-grey wow fadeInUp mb-2">Curriculum - LEARNING and FUN model, with latest offerings <br />on Coding,Robotics, AI and Data Science.</p>
                    {/* <p class="small text-grey wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">Grades 1 to 10</p> */}
                    <ul className="list-unstyled">
                      <li className="animated fadeInLeft" style={{ animationDelay: '.5s' }}><i className="fas fa-arrow-circle-right" />&nbsp; Expert IT Faculty</li>
                      <li className="animated fadeInLeft" style={{ animationDelay: '1s' }}><i className="fas fa-arrow-circle-right" />&nbsp; Personalized 1:1 Training</li>
                    </ul>
                    <Link to="/" className="btn btn-service rounded-0 text-white">
                      <span className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                        <span>Prelaunch Offer</span>
                        <i className="ml-1 fa fa-arrow-right bg-white rounded-circle text-theme shadow-none" aria-hidden="true" />
                      </span>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="position-absolute" style={{ top: 100, left: -10, opacity: .7 }}>
            <img src="images/3.png" />
          </div>
        </section>

        <section className="some_desc py-5 text-center">
          <Container>
            <Row>
              <Col md={12}>
                <h4 className="pb-2" style={{ color: '#206f93' }}><b>IANTKIDS</b> is a Learning Platform catering to Students from Class 1 to Class 12.</h4>
                <h6>The Platform is powered by IANT-The World’s Biggest IT Training and Global Certification Company with 21 Years of IT Training Experience. </h6>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="satisfied">
          <Container>
            <Row>

              <Col xs={12} md={12} sm={12} className="wp-block-column">

                <div className="d-md-flex">
                  <div style={{ flexBasis: '35%' }}>
                    <h1 className="" style={{}}> Learn Coding with<br /> the IT Champion - </h1>
                  </div>
                  <div style={{ flexBasis: '65%' }}>
                    <h4 className="pb-3">IANT
                    The World’s Biggest IT Training & Certification Company.Make your Kids LEARN 21st Century Skills
            </h4>
                    <h6>SKILL-EMPOWER-FUTURE READY</h6>
                  </div>
                </div>

              </Col>

            </Row>
          </Container>
          <div className="position-absolute angle" style={{ right: 0, transform: 'rotate(180deg)', top: -67, opacity: .7, }}>
            <img src="images/3.png" />
          </div>
        </section>

        <section className="why_coding">
          <Container>
            <Row>
              <Col md={12} className="text-center">
                <h1 className="pb-1">Reasons for your Child to Learn Coding NOW !</h1>
                <p>Its not about learning to CODE - its about learning to THINK!</p><br />
              </Col>

              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/resilience.png" />
                  <h4>Resilience</h4>
                </div>
              </Col>

              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/math.png" />
                  <h4>Writing</h4>
                </div>
              </Col>
              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/problemsolve.png" />
                  <h4>Problem Solving</h4>
                </div>
              </Col>

              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/innovation.png" />
                  <h4>Innovation</h4>
                </div>
              </Col>
              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/math.png" />
                  <h4>Math</h4>
                </div>
              </Col>
              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/creativity.png" />
                  <h4>Creativity</h4>
                </div>
              </Col>
              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/thinking.png" />
                  <h4>Critical Thinking</h4>
                </div>
              </Col>
              <Col>
                <div className="why_coding_block">
                  <img src="images/icon/confidence.png" />
                  <h4>Confidence</h4>
                </div>
              </Col>


            </Row>

            <div className="courses_block" style={{ BoxShadow: '0 3px 5px 0px rgba(0,0,0,0.13)' }}>
              <div className="p-3 border-bottom">
                <h4 className="font-weight-bold">Introduction to Programming</h4>
                <p className="mb-0 text-danger font-weight-bold">Online courses for kids !</p>
              </div>
              <div className="d-md-flex justify-content-between align-items-center">
                <div style={{ flexBasis: '40%', padding: 25 }}>
                  <img src="images/comp.webp" className="img-fluid" />
                </div>
                <div style={{ flexBasis: '25%', padding: '20px 0' }}>
                  <ul className="list-unstyled">
                    <li className="courses_list">
                      <img src="images/ic1.webp" className="float-left mr-3" />
                      <h6>MY FIRST ANIMATION</h6>
                      <small>7 - 10 year olds | 3 levels</small>
                    </li>
                    <li className="courses_list">
                      <img src="images/ic2.webp" className="float-left mr-3" />
                      <h6>MY FIRST GAME</h6>
                      <small>10-14 year olds | 2 levels</small>
                    </li>
                    <li className="courses_list">
                      <img src="images/ic3.webp" className="float-left mr-3" />
                      <h6>MY FIRST WEBSITE</h6>
                      <small>10-14 year olds | 2 levels</small>
                    </li>
                    <li className="courses_list">
                      <img src="images/ic4.webp" className="float-left mr-3" />
                      <h6>MY FIRST PYTHON CODE</h6>
                      <small>12-14 year olds | 3 levels</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section className="process position-relative">
          <Container>
            <Row>
              <Col md={12} className="text-center">
                <h1>Getting Started is as Easy as 123.<br /> Let’s Get Started!</h1>
              </Col>
              <Col md={11} className="mx-auto d-md-flex d-sm-flex justify-content-between new_bg">
                <div className="position-relative img_block">
                  <img src="images/p1.jpg" className="img-fluid" />
                  <span>1</span>
                  <h3>Choose your Course</h3>
                </div>
                <div className="position-relative img_block">
                  <img src="images/p2.jpg" className="img-fluid" />
                  <span className="two">2</span>
                  <h3>Learn From Live Classes</h3>
                </div>
                <div className="position-relative img_block">
                  <img src="images/p3.jpg" className="img-fluid" />
                  <span className="three">3</span>
                  <h3>Become a Certified Coder!</h3>
                </div>
              </Col>
              <Col md={12} className="text-center mt-5">
                <Link to="/" className="btn btn-service rounded-0 text-white">
                  <span className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                    <span>Prelaunch Offer</span>&nbsp;
            <i className="fa fa-arrow-right bg-white rounded-circle text-theme shadow-none" aria-hidden="true" />
                  </span>
                </Link>
              </Col>
            </Row>
          </Container>
          <div className="position-absolute" style={{ right: 0, transform: 'rotate(180deg)', top: 0 }}>
            <img src="images/4.png" />
          </div>
        </section>

        <section className="video_part">
          <Container>
            <Row>
              <Col md={12} className="text-center">
                <h1>Coding With kids inspires next generation<br /> of computer whizzes</h1>
              </Col>
              <Col md={12} className="text-center">
                <div>
                  <iframe title="vimeo-player" src="https://player.vimeo.com/video/393999309" width={640} height={360} frameBorder={0} className="rounded" allowFullScreen />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="banner_with_student position-relative">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg={8} md={8} sm={12} xs={12} className="text-center">
                <div className="info_title">
                  Try for Free and Get Access to Dozens of <br />Online Computer Coding Classes for Kids!
        </div>
                <div className="info_text text-center">
                  <ul className="d-md-flex text-white justify-content-center list-unstyled">
                    <li><strong>58</strong> Computer Coding Courses</li>&nbsp;|&nbsp;
            <li><strong>220</strong> Online Coding Quests</li>&nbsp;|&nbsp;
            <li><strong>600</strong> Computer Coding Challenges</li>
                  </ul>
                </div>
                <div className="front_buttons_action">
                  <Link to="/" className="btn btn-service text-white my-3 rounded-0">Prelaunch Offer<i className="fa fa-angle-right" /></Link>
                  <p className="text-white font-weight-bold">Develop your Kid's 3C's: Curiosity, Creativity and Critical Thinking.</p>
                </div>
                <p className="text-white">Or... <a href="#" className="text-white">Click Here</a> To Give As Gift!</p>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12} className="pr-md-0">
                <img src="images/banner3.jpg" className="img-fluid" />
              </Col>
            </Row>
          </Container>
          <div className="position-absolute" style={{ bottom: '-140px', left: 0 }}>
            <img src="images/download.svg" />
          </div>
        </section>


        {/* <section className="expert_block">
          <Container>
            <Row>
              <div className="col-md-12 text-center">
                <h1>Our Expert Coding Educators</h1>
              </div>
              <Col>
                <div className="teachers_block text-center p-2">
                  <img src="images/t1.png" className="img-fluid mb-3 w-75" />
                  <h6 className>Dr. Farida Khan</h6>
                  <p className="text-secondary">PHD IITB, Gold Medalist</p>
                </div>
              </Col>
              <Col>
                <div className="teachers_block text-center p-2">
                  <img src="images/t2.png" className="img-fluid mb-3 w-75" />
                  <h6 className>Rajeev Jha</h6>
                  <p className="text-secondary">Advanced Game Designer, TFI Fellow.</p>
                </div>
              </Col>
              <Col>
                <div className="teachers_block text-center p-2">
                  <img src="images/t3.png" className="img-fluid mb-3 w-75" />
                  <h6 className>Abhijeet Gawande</h6>
                  <p className="text-secondary">IB Educational Technologist, AI Expert</p>
                </div>
              </Col>
              <Col>
                <div className="teachers_block text-center p-2">
                  <img src="images/t4.png" className="img-fluid mb-3 w-75" />
                  <h6 className>Gauri Parulkar</h6>
                  <p className="text-secondary">Computer Scientist, Entrepreneur, Science Quotient</p>
                </div>
              </Col>
              <Col>
                <div className="teachers_block text-center p-2">
                  <img src="images/t5.png" className="img-fluid mb-3 w-75" />
                  <h6 className>Rahul Singh</h6>
                  <p className="text-secondary">Data Scientist, Deep Learning &amp; NLP expert.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}


        <section className="price_carriculam" id="courses">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Courses &amp; Pricing</h1>
              </div>
              <div className="col-md-12 card border-0 rounded-0">
                <div className="row">
                  <div className="col-md-3 px-0">
                    {
                      this.state.loading1 === true ? (
                        <span>Loading...</span>
                      ) : (
                          <div>
                            {this.state.data.map(item =>
                              <div className="nav flex-column nav-pills left_menu" id={item.kgmGuId} role="tablist" aria-orientation="vertical">
                                {
                                  this.state.KGMGuId === item.kgmGuId ?
                                    (
                                      <div>
                                        <a className="nav-link active" id={item.kgmGuId} data-toggle="pill" href={"#" + item.kgmGuId} role="tab" onClick={() => this.onChangeValue(item.kgmGuId)} aria-controls="v-pills-home" aria-selected="true" >
                                          <h6 className="font-weight-bold mb-0">{item.kgmTitle}</h6>
                                          <span className="small"> Class ({item.kgmClassTitle})</span>
                                        </a>
                                      </div>
                                    )
                                    :
                                    (
                                      <div>
                                        <a className="nav-link" id={item.kgmGuId} data-toggle="pill" href={"#" + item.kgmGuId} role="tab" onClick={() => this.onChangeValue(item.kgmGuId)} aria-controls="v-pills-home" aria-selected="" >
                                          <h6 className="font-weight-bold mb-0">{item.kgmTitle}</h6>
                                          <span className="small"> Class ({item.kgmClassTitle})</span>
                                        </a>
                                      </div>
                                    )
                                }
                              </div>
                            )}
                          </div>
                        )
                    }
                  </div>
                  <div className="col-md-9 px-0" style={{ boxShadow: '0 5px 10px 2px rgba(0,0,0,0.13)' }}>
                    <div className="tab-content p-3" id="v-pills-tabContent">
                      <div className="tab-pane fade show active" id={this.state.KGMGuId} role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="row">
                          {
                            this.state.loading2 === true ? (
                              <span>Loading...</span>
                            ) : (
                                this.state.Plan.map(item =>
                                  <div className="col col-md-4 col-sm-6">
                                    <div className="pricingTable">
                                      <div className="pricingTable-header">
                                        <div>
                                          <h3 className="title">{item.kdpTitle}</h3>
                                          {item.kdpIsOffer === true ? (
                                            <div>
                                              <span className="price-value"> {new Intl.NumberFormat('en-IN', {
                                                style: 'currency',
                                                currency: 'INR'
                                              }).format(item.kdpOfferPrice)}  &nbsp;<small className="text-secondary"><del> {new Intl.NumberFormat('en-IN', {
                                                style: 'currency',
                                                currency: 'INR'
                                              }).format(item.kdpPrice)}</del></small></span><br />
                                            </div>
                                          ) :
                                            (
                                              <div>
                                                <span className="price-value"> {new Intl.NumberFormat('en-IN', {
                                                  style: 'currency',
                                                  currency: 'INR'
                                                }).format(item.kdpPrice)} </span><br />
                                              </div>
                                            )}
                                          {/* <small>Price Per Class  {new Intl.NumberFormat('en-IN', {
                                          style: 'currency',
                                          currency: 'INR'
                                        }).format(item.kdpPerClass)}</small> */}
                                        </div>
                                      </div>
                                      <div className="bg-theme2 p-2 d-md-flex justify-content-between align-items-center">
                                        <h6 className="mb-0 text-white font-weight-bold">{item.kdpClass}</h6>
                                        {
                                          item.kdpIsBase == true ?
                                            (
                                              <div>
                                                <span className="badge badge-danger">{item.kdpBaseTitle}</span>
                                              </div>
                                            )
                                            : null
                                        }
                                      </div>
                                      <div className="pricing-content text-left" data-heading={item.kgmTitle}>
                                        <h5 className="pb-2">Curriculum Includes</h5>

                                        <p>
                                          {item.kdpDescription}
                                        </p>
                                      </div>
                                      {
                                        item.kdpIsFile == true ? (

                                          <h6 className="view_pdf position-relative">
                                            <span></span>
                                            {/* {UserService.API_URL3 + "File/Plan/" + item.kdpFile} */}
                                            <a className="d-block w-100 p-1 text-dark position-relative" style={{ textDecoration: 'none', zIndex: 99 }} href={'/courses/' + item.kgmTitle.toLowerCase()} target="_blank">View Course Details</a>
                                            <span className="new"></span>
                                          </h6>
                                        ) : null
                                      }

                                      <div className="pricingTable-signup">
                                        <a href={'/'}><span>Buy Now</span></a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
