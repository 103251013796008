import React, { Component } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BackToTop from "react-back-to-top-button";
import UserService from '../../services/UserService';
import axios from 'axios';
import Cookies from 'universal-cookie';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Register extends Component {


  constructor(props) {
    super(props);

    this.state = {
      INQGuId: '',
      KGMGuId: '',
      INQParentName: '',
      INQParentEmail: '',
      INQParentMobile: '',
      INQPassword: '',
      INQKidsName: '',
      INQIsLaptop: false,
      INQAlternetNumber: '',
      INQAlternetEmail: '',
      INQCreateDate: '',
      INQStatus: '',
      INQIsDemo: '',
      isSubmitting: false,
      E: '',
      data: [],
      nameerr: '',
      emailerrer: '',
      mobileerroe: '',
      chnames: '',
      ACTCountryCode: '',
      loading1: false,
      loading2: true,
    };



    fetch(UserService.API_URL1 + 'UserKidsGroup/List', {
      method: 'GET',
      headers: {
        //Authorization: 'Bearer ' + localStorage.getItem('tokens'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'

      }
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ data: response, loading: false, loading2: false });
      },
        err => {
          console.log(err);
          // this.props.history.push('/Login');
        });



    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeValue1 = this.onChangeValue1.bind(this);
    this.CheckEmail = this.CheckEmail.bind(this);
    this.CheckMobile = this.CheckMobile.bind(this);
    this.CheckName = this.CheckName.bind(this);
    this.CheckChildName = this.CheckChildName.bind(this);

  }


  CheckEmail() {

    if (this.state.INQParentEmail === '') {
      this.setState({ emailerrer: "Places Enter Email" })
    }
    else {
      this.setState({ emailerrer: "" })
    }

    // alert(this.state.INQParentEmail)
    axios.get(UserService.API_URL1 + 'Inquiry/CheckEmail/' + this.state.INQParentEmail).then(resp => {

      console.log(resp.data)
      const Email = resp.data;

      if (Email === 1) {
        this.setState({ emailerrer: "This Email Is Already Used" })
      }
    });
  }

  CheckMobile() {

    if (typeof this.state.INQParentMobile !== "undefined") {

      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(this.state.INQParentMobile)) {

        this.setState({ mobileerroe: "Please enter only number" })

      } else if (this.state.INQParentMobile.length != 10) {

        this.setState({ mobileerroe: "Please enter only number" })

      }
      else {
        this.setState({ mobileerroe: "" })
      }

    }

    axios.get(UserService.API_URL1 + 'Inquiry/CheckMobile/' + this.state.INQParentMobile).then(resp => {

      console.log(resp.data)
      const Email = resp.data;

      if (Email === 1) {
        this.setState({ mobileerroe: "This Mobile Is Already Exit" })
      }
    });
  }



  CheckName() {

    if (this.state.INQParentName === '') {
      this.setState({ nameerr: "Places Enter Email" })
    }
    else {
      this.setState({ nameerr: "" })
    }

  }


  CheckChildName() {
    if (this.state.INQKidsName === '') {
      this.setState({ chnames: "Places Enter Child Name" })
    }
    else {
      this.setState({ chnames: "" })
    }
  }




  componentDidMount() {
    // Validation


  }


  onChangeValue(event) {
    console.log(event.target.value);
    this.setState({ KGMGuId: event.target.value })
  }

  onChangeValue1(event) {
    console.log(event.target.value);
    this.setState({ INQIsLaptop: event.target.value })
  }




  handleSubmit(event) {
    event.preventDefault();
    console.log(event);


    this.setState({
      isSubmitting: true
    });


    const s = Boolean(this.state.INQIsLaptop)

    console.log(this.state.INQParentName, this.state.INQParentEmail, this.state.INQParentMobile, this.state.INQKidsName, this.state.KGMGuId, this.state.INQIsLaptop)

    this.setState({ loading1: true });

    axios.post(UserService.API_URL1 + 'Inquiry/Create', {
      INQParentName: this.state.INQParentName,
      INQParentEmail: this.state.INQParentEmail,
      INQParentMobile: this.state.INQParentMobile,
      INQKidsName: this.state.INQKidsName,
      KGMGuId: this.state.KGMGuId,
      INQIsLaptop: s,
      INQGuId: this.state.INQGuId
    }, {
      headers: {
        //  Authorization: 'Bearer ' + localStorage.getItem('tokens'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data !== null) {

          alert('Successfully Registered ')

          // localStorage.setItem('ID', response.data.inqGuId)

          const cookies = new Cookies();

          // cookies.set('ID', response.data.inqGuId, { path: 'http://localhost:3001/schedule' });
          // cookies.set('tkn', response.data.inqWebsiteName, { path: 'http://localhost:3001/schedule' });

          cookies.set('ID', response.data.inqGuId, { path: '/', domain: '.iantkids.com' });
          cookies.set('tkn', response.data.inqWebsiteName, { path: '/', domain: '.iantkids.com' });


          this.setState({ loading1: false })

          window.location.href = 'https://coder.iantkids.com'
          // window.location.href = 'http://localhost:3001/schedule'

        }
        else {
          alert('Error ')
          this.setState({ loading1: false })
        }

      })
      .catch(function (error) {
        console.log(error);
        this.setState({ loading1: false })
      });

  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });

  }



  render() {

    const { loading1, loading2 } = this.state;
    return (
      <div className="account_page">
        <div className="form-bg h-100">
          <Container className="h-100">
            <Row className="align-items-center h-100">
              <Col lg={7} md={7}>
                <div className="pr-md-5 d-md-block d-none">
                  <Link to="/"><img src="images/kids4.png" className="img-fluid" /></Link>
                </div>
              </Col>

              <Col lg={5} md={5} className="px-md-0">
                <form className="form-sample" onSubmit={this.handleSubmit} >
                  <div className="form-container">
                    <div className="form-icon">
                      <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <h4>Book Your Session</h4>
                        <Link to="/"><img
                          src="images/logo.jpg"
                          className="img-fluid logo mt-md-n3"
                        /></Link>
                      </div>
                      <form>
                        <div className="form-row">
                          <Col md={6} className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Parent's Email ID"
                              name="INQParentEmail" value={this.state.INQParentEmail}
                              required onChange={this.handleChange}
                              onBlur={this.CheckEmail}
                            />
                            <span style={{ color: 'red' }}>{this.state.emailerrer}</span>
                          </Col>
                          <Col md={6} className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Parent's Name"
                              name="INQParentName" value={this.state.INQParentName}
                              required onChange={this.handleChange}
                              onBlur={this.CheckName}
                            />
                            <span style={{ color: 'red' }}>{this.state.nameerr}</span>
                          </Col>

                          <Col md={2} xs={3}>
                            <select name="INQGuId" className="form-control ap-none" required onChange={this.handleChange}>
                              <option value="+91">+91</option>
                            </select>
                          </Col>
                          <Col md={4} xs={9} className="form-group">

                            <input
                              type="number"
                              className="form-control"
                              placeholder="Parent's Mobile"
                              name="INQParentMobile" value={this.state.INQParentMobile}
                              required onChange={this.handleChange}
                              onBlur={this.CheckMobile}
                            />

                            <span style={{ color: 'red' }}>{this.state.mobileerroe}</span>
                          </Col>
                          <Col md={6} className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Child's Name"
                              name="INQKidsName" value={this.state.INQKidsName}
                              required onChange={this.handleChange}
                              onBlur={this.CheckChildName}
                            />
                            <span style={{ color: 'red' }}>{this.state.chnames}</span>
                          </Col>
                          <Col md={12} className="form-group">
                            <h6 className="pb-2">
                              Your Child's Grade/Class in School?
                          </h6>
                            {loading2 === true ? (
                              <span>Loading...</span>
                            ) : (
                                <div className="d-flex plan" onChange={this.onChangeValue}>
                                  {this.state.data.map(item =>
                                    <div>

                                      <input
                                        type="radio"
                                        name="radio1"
                                        required
                                        value={item.kgmGuId}
                                        id={item.kgmGuId}
                                        defaultValue="free"
                                      />
                                      <label
                                        className="free-label four col"
                                        htmlFor={item.kgmGuId}
                                      >
                                        Class <br />
                                        <b className>{item.kgmClassTitle}</b>
                                      </label>
                                    </div>
                                  )
                                  }
                                </div>
                              )
                            }

                          </Col>
                          <Col md={12} className="form-group">
                            <h6 className="pb-2">
                              Do you have Laptop/PC at home for class?
                          </h6>

                            <div className="cntr" onChange={this.onChangeValue1}>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="customRadio"
                                  name="example"
                                  value='true'
                                  defaultValue="customEx"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customRadio"
                                >
                                  Yes
                              </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="customRadio2"
                                  name="example"
                                  value='false'
                                  defaultValue="customEx"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customRadio2"
                                >
                                  No
                              </label>
                              </div>
                            </div>
                          </Col>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-block btn-theme mt-2 text-white p-3 font-weight-bold"
                          disabled={loading1}
                        >
                          {loading1 && (
                            <span>Loading...</span>
                            // <img src="/re2.gif" style={{ marginRight: "5px" }} />
                          )}

                          {!loading1 && <span>Schedule my Session</span>}</button>

                        <p className="p-2 text-center mb-0">
                          Already have an account? <a href="https://coder.iantkids.com/Login">Sign In</a> instead
                      </p>
                      </form>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </div>

        <BackToTop
          showOnScrollUp
          showAt={100}
          speed={2000}
          easing="easeInOutQuint"
          style={{ zIndex: 999, fontSize: 20 }}
        >
          {/* <button className="btn btn-theme text-white shadow">
            <span><i class="fas fa-chevron-up"></i></span>
          </button> */}
          <img src="/images/rocket.png" width="60" />
        </BackToTop>

      </div>
    );
  }
}

export default Register;
