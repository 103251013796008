import { Component } from 'react';
export default class UserService extends Component {
	static API_URL1 = 'https://api.iantkids.com/api/';
	//static API_URL2 = 'https://erpapinet.mimrox.com/';
	static API_URL3 = 'https://admin.iantkids.com/'

	//static API_URL1 = 'https://localhost:44399/api/';
	//static API_URL2 = 'https://api.iantkids.com/api/';
	//static API_URL3 = 'https://localhost:44338/'
}
