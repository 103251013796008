import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Modal,
  Form,
  NavLink,
} from "react-bootstrap";

import { Link, Redirect } from "react-router-dom";
import BackToTop from "react-back-to-top-button";
//import Typed from "react-typed";

class Header extends Component {
  onClick() {
    window.location.href = "/countries";
    //window.location.to = "/countries";
  }

  render() {
    return (
      <div>

        <Navbar className="navbar navbar-expand-lg header" expand="lg">
          <Container>
            <Navbar.Brand href="/home">
              <img src="../images/logo.jpg" className="logo mt-md-n3" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" className="mobile_toggle">
              <span className="fas fa-align-center small" />
            </Navbar.Toggle>

            <Navbar.Collapse className="d-md-block d-none" id="basic-navbar-nav">
              <Nav className="mr-auto menu">
                <Link to="/home">Home</Link>
                <Link to="/about">About Us</Link>

                <Link to="/">Courses</Link>
                {/* <Link to="/">Kids coding online</Link> */}
                {/* <a href="https://coder.iantkids.com/Login"><i class="fas fa-sign-in-alt small"></i>&nbsp;Sign In</a>
                <Link to="/register"><i class="fas fa-user small"></i>&nbsp;Sign Up</Link> */}
              </Nav>
              <Link
                to="/trail"
                className="btn btn-theme text-white animated headShake rounded-0 px-2 mr-1"
                style={{ animationIterationCount: "infinite", fontSize: 15, }}
              >
                <i className="far fa-calendar-alt" />
                &nbsp;Book a Free Trial
              </Link>
              <Link
                to="/"
                className="btn btn-theme2 text-white animated headShake rounded-0  px-2"
                style={{ animationIterationCount: "infinite", fontSize: 15, zIndex: 999 }}
              >

                &nbsp;Prelaunch Offer
              </Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* <nav className="navbar navbar-expand-lg header">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src="images/logo.png" className="logo" />
            </a>
            <button
              className="navbar-toggler mobile_toggle"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="fas fa-align-center small" />
            </button>
            <div
              className="collapse navbar-collapse d-md-block d-none"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto menu">
                <li className="nav-item active">
                  <a className="nav-link" href="#">
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Courses
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Kids coding online
                  </a>
                </li>
                <li className="nav-item d-flex align-items-center">
                  <a className="nav-link btn" href="#">
                    <i className="fas fa-user small" />
                    &nbsp;Sign In
                  </a>
                  /
                  <a className="nav-link btn" href="#">
                    &nbsp;Sign Up
                  </a>
                </li>
              </ul>

              <a
                href="#"
                className="btn btn-theme text-white animated headShake font-weight-bold"
                style={{ animationIterationCount: "infinite" }}
              >
                <i className="far fa-calendar-alt" />
                &nbsp;Book a Free Trial
              </a>
            </div>
          </div>
        </nav> */}

        <div
          className="collapse navbar-collapse d-md-none d-block mobile_sidebar"
          id="mobile_sidebar"
        >
          <button
            className="btn  text-white float-right close_icon position-absolute"
            style={{ right: 5 }}
          >
            ×
          </button>
          <ul className="navbar-nav mr-auto menu pt-5">
            <li
              className="nav-item active wow fadeInRight"
              data-wow-duration=".5s"
              data-wow-delay=".5s"
            >
              <Link to="/">
                Homes <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li
              className="nav-item wow fadeInRight"
              data-wow-duration=".8s"
              data-wow-delay=".8s"
            >
              <Link to="/">
                About Us
              </Link>
            </li>
            <li
              className="nav-item wow fadeInRight"
              data-wow-duration="1.1s"
              data-wow-delay="1.1s"
            >
              <a href="#courses">
                Courses
              </a>
            </li>
            <li
              className="nav-item wow fadeInRight"
              data-wow-duration="1.4s"
              data-wow-delay="1.4s"
            >
              <Link to="/">
                Kids coding online
              </Link>
            </li>
            <li
              className="nav-item wow fadeInRight"
              data-wow-duration="1.7s"
              data-wow-delay="1.7s"
            >
              <a href="https://coder.iantkids.com/Login">
                <i className="fas fa-user small" />
                &nbsp;Sign In
              </a>
            </li>
            <li
              className="nav-item wow fadeInRight"
              data-wow-duration="2s"
              data-wow-delay="2s"
            >
              <Link to="/register">
                <i className="fas fa-user small" />
                &nbsp;Sign Up
              </Link>
            </li>
          </ul>
          <div className="px-2">
            <Link to="/register"
              className="btn btn-freetrial btn-block animated headShake mt-5"
              style={{ animationIterationCount: "infinite" }}
            >
              <i className="far fa-calendar-alt" />
              &nbsp;Book a Free Trial
            </Link>
          </div>
          <div className="position-absolute" style={{ top: 32, zIndex: -9 }}>
            <img src="images/3.png" />
          </div>
        </div>

        <BackToTop
          showOnScrollUp
          showAt={100}
          speed={2000}
          easing="easeInOutQuint"
          style={{ zIndex: 999, fontSize: 20 }}
        >
          {/* <button className="btn btn-theme text-white shadow">
            <span><i class="fas fa-chevron-up"></i></span>
          </button> */}
          <img src="/images/rocket.png" width="60" />
        </BackToTop>
      </div>
    );
  }
}

export default Header;
