import React from "react";
import logo from "./logo.svg";
import "./Styles.css";
import "./animate.css";
//import "./wow.min";
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

import Layout from "./Components/Layout";
import Register from "./Views/Registration/Register";
import CoursePlan from './Views/CourcesPlan/courseplan';
import Registercource from './Views/Courseplanregister/Courseplanreg';
import Payment from './Views/Checkout/Checkout';
import Success from './Views/Success/Success';
import Trail from './Views/Trail/trail';

//import $ from 'jquery';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          {/* <Route  path="/" name=""  component={Layout} /> */}

          {/* <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          /> */}

          <Route
            exact
            path="/trail"
            name="Trial"
            render={(props) => <Trail {...props} />}
          />

          <Route
            exact
            path="/"
            name="CoursePlan"
            render={(props) => <CoursePlan {...props} />}
          />

          <Route
            exact
            path="/registers"
            name="registers"
            render={(props) => <Registercource {...props} />}
          />

          <Route
            exact
            path="/success"
            name="Booking Page"
            render={(props) => <Success {...props} />}
          />

          <Route
            exact
            path="/checkout"
            name="checkout"
            render={(props) => <Payment {...props} />}
          />


          <Route path="/" name="" render={(props) => <Layout {...props} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default withRouter(App);
